/*
Inflexer Face: frontend of Inflexer

__author__ = 'Taeyang Yang (tyami@naver.com)'
__copyright__ = 'Copyright (C) 2024-, Taeyang Yang. All rights reserved.'
*/
import React, { useState, useEffect, useRef } from 'react';
import {
    BASE_WEB_URL,
    LOGO_IMG,
    NOTICE_ICON,
    NOTICE_URL,
    OPINION_ICON,
    OPINION_URL,
    NOTICE_MESSAGE,
    API_ENDPOINT_COUNT,
    API_ENDPOINT_MAP,
    GOOGLE_ADSENSE_CLIENT_ID,
    GOOGLE_ADSENSE_MAIN_SLOT_ID
} from '../constants.js';
import Filters from './Filter.js';
import GoogleAdSense from '../common/GoogleAdsense.js';
import { scrollToTop } from '../common/utils';
import ResultsMap from './ResultsMap.js';
import '../App.css';

const AppMap = ({ showNotice, showBannerAd, showFooterAd }) => {
    const [query, setQuery] = useState('');
    const inputRef = useRef(null);
    const [mediaFilters, setMediaFilters] = useState(['BP_']);
    const [typeFilters, setTypeFilters] = useState(['VST']);
    const [pointFilters, setPointFilters] = useState(['O', 'X']);
    const [targetFilter, setTargetFilter] = useState('TOTAL');
    const [markers, setMarkers] = useState([]);
    const [countMessage, setCountMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const removeInputFocus = () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const search = async () => {
        if (!query.trim()) {
            return;
        }
        setLoading(true);
        const params = new URLSearchParams();
        params.append('query', query);
        if (mediaFilters.length > 0) {
            mediaFilters.forEach((media) => params.append('media', media));
        }
        if (typeFilters.length > 0) {
            typeFilters.forEach((type) => params.append('type', type));
        }
        if (pointFilters.length > 0) {
            pointFilters.forEach((point) => params.append('point', point));
        }
        params.append('target', targetFilter);

        try {
            const response = await fetch(`${API_ENDPOINT_MAP}?${params.toString()}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            setMarkers(data.result);
            if (data.is_valid) {
                if (data.result.length === 0) {
                    setCountMessage('지금은 적합한 체험이 없네요');
                } else {
                    setCountMessage(
                        `당신을 위한 ${data.num_result}개의 체험이 있어요!<br /><small>마커를 클릭하면 체험단 사이트로 이용해요.<br/><a href=${BASE_WEB_URL} target="_blank" rel="noopener noreferrer">테이블 페이지</a>에서 더 많은 캠페인을 볼 수 있어요</small>`
                    );
                }
            } else {
                setCountMessage(`결과가 너무 많아서 지도에 띄울 수 없어요! (${data.num_result}건)`);
            }
        } catch (error) {
            console.error('Failed to fetch:', error);
            setCountMessage('지금은 사용자가 많아요. 잠시 후 다시 시도해주세요!');
        } finally {
            setLoading(false);
            if (inputRef.current) {
                inputRef.current.blur();
            }
        }
    };

    const getCount = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_ENDPOINT_COUNT}?for_map=True`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setCountMessage(
                `주소가 있는 ${data.num_campaign}개의 체험이 있어요!<br/><small><strong>${data.num_platform}개 플랫폼을 한꺼번에 찾아 드릴게요. </strong><br /><a href="https://blog.naver.com/tyami/223500172766" target="_blank">검색 가이드</a>를 참고해서 지역과 키워드를 입력해주세요.<br />(예: 서울 카페, 제주 펜션, 충남 캠핑, 강원 숙박...)<br /></small>`
            );
        } catch (error) {
            console.error('Failed to fetch count:', error);
            setCountMessage('서버 보수 중이예요! 잠시 후 다시 시도해주세요.');
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (filterType, value) => {
        let setFilter;
        let filters;

        switch (filterType) {
            case 'media':
                setFilter = setMediaFilters;
                filters = mediaFilters;
                break;
            case 'type':
                setFilter = setTypeFilters;
                filters = typeFilters;
                break;
            case 'point':
                setFilter = setPointFilters;
                filters = pointFilters;
                break;
            default:
                return; // Unknown filter type, do nothing
        }

        setFilter(filters.includes(value) ? filters.filter((item) => item !== value) : [...filters, value]);
    };

    const handleTargetRadioChange = (value) => {
        setTargetFilter(value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    useEffect(() => {
        getCount(); // 컴포넌트가 처음 렌더링될 때 데이터 개수를 가져옵니다.
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (query.trim()) {
            search(); // query가 존재할 때만 search를 실행합니다.
        }
    }, [mediaFilters, targetFilter, pointFilters]);

    return (
        <div className={showNotice ? 'container-notice' : 'container'}>
            {showNotice && (
                <div className='notice'>
                    <div className='notice-content' dangerouslySetInnerHTML={{ __html: NOTICE_MESSAGE }}></div>
                </div>
            )}

            <section className='header'>
                <div className='title' id='title' aria-label='인플렉서(INFLEXER)'>
                    <a href={BASE_WEB_URL} title='인플렉서 홈페이지로 이동'>
                        <img src={LOGO_IMG} alt='인플렉서(INFLEXER) 로고' className='logo' />
                        <span className='sr-only'>인플렉서(INFLEXER)</span>
                    </a>
                </div>
                <div className='header_link_box'>
                    <a id='opinion' href={OPINION_URL} target='_blank' title='의견 제출 폼'>
                        <img src={OPINION_ICON} alt='의견' className='icon_opinion' />
                    </a>
                    <a id='maker' href={NOTICE_URL} target='_blank' title='공지사항'>
                        <img src={NOTICE_ICON} alt='공지' className='icon_notice' />
                    </a>
                </div>
            </section>

            <section className='main'>
                <div className='slogan' id='slogan'>
                    지도를 보고 쉽게, 빠르게, 편리하게!
                </div>

                <div className='filter_button'>
                    <Filters
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        mediaFilters={mediaFilters}
                        handleCheckboxChange={handleCheckboxChange}
                        typeFilters={typeFilters}
                        pointFilters={pointFilters}
                    />
                </div>

                <div className='input_container'>
                    <input
                        ref={inputRef}
                        type='text'
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder='검색어를 입력하세요 (예: 서울 강남, 제주 펜션)'
                    />
                    <button onClick={search} disabled={loading} id='search'>
                        검색
                    </button>
                </div>

                <div
                    className='count_message'
                    dangerouslySetInnerHTML={{ __html: countMessage }}
                ></div>

                {showBannerAd && (
                    <div
                        className='ads_google_banner'
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100px',
                            width: '100%',
                            padding: '10px',
                        }}
                    >
                        <GoogleAdSense
                            client={GOOGLE_ADSENSE_CLIENT_ID}
                            slot={GOOGLE_ADSENSE_MAIN_SLOT_ID}
                            style={{ display: 'block' }}
                            format='auto'
                            responsive='true'
                        />
                    </div>
                )}

                <div className='filter_target'>
                    <label>
                        <input
                            type='radio'
                            name='target'
                            value='TOTAL'
                            checked={targetFilter === 'TOTAL'}
                            onChange={() => handleTargetRadioChange('TOTAL')}
                        />
                        전체
                    </label>
                    <label>
                        <input
                            type='radio'
                            name='target'
                            value='TODAY'
                            checked={targetFilter === 'TODAY'}
                            onChange={() => handleTargetRadioChange('TODAY')}
                        />
                        오늘오픈
                    </label>
                </div>

                <div className='result'>
                    <ResultsMap markers={markers} removeInputFocus={removeInputFocus} />
                </div>

                <div className='goto_top_button'>
                    <button className="go-to-top" onClick={scrollToTop}>
                        상단으로
                    </button>
                </div>

            </section>

            {showFooterAd && <section className='footer'></section>}
        </div>
    );
};

export default AppMap;
